<template>
    <div class="bg-black aspect-video relative">
        <div class="my-bg bg-cover bg-no-repeat ">
            <div class="absolute right-[52px] top-[25px]">
                <lang-select class=""></lang-select>
            </div>
            <div class="pt-[44px] ml-[360px] ">
                <img src="@/assets/p/platform.webp" class="h-[62px]">
                <div class="text-[46px] text-white font-bold mt-[20px] max-w-[30vw]" v-html="activeData.text1"></div>
                <img src="@/assets/p/line.webp" class="ml-[49px] -translate-y-[5px] h-[39px]">
            </div>
            <img :src="activeData.bg" class="w-full">
            <img :src="activeData.extra" class="w-full absolute bottom-[0px] left-0 overflow-hidden "/>
            <div :class="contentStyle"
                 class="bg-[#232939] py-[58px] px-[46px]
                absolute top-[132px] right-[320px] rounded-[20px] text-center
">
                <!-- 文字内容框 -->
                <div class="flex justify-between px-[20px]">
                    <img src="@/assets/p/extra.svg">
                    <div class="text-[48px] text-white" :class="$i18n.locale !== 'vi_VN' ? 'custom-font':''"
                         v-html="activeData.text2"></div>
                    <img src="@/assets/p/extra.svg" class="rotate-y-[180deg]">
                </div>
                <div class="mt-[13px] text-[#F9A51A] text-[25px] text3 font-bold" v-html="activeData.text3"></div>
                <div class="mt-[26px] text-[17px] text-white">{{ activeData.text4 }}</div>
                <div class="my-[31px]">
                    <div @click="doDownload"
                         class="bg-[#F9A51A] border-0 py-[21px] min-w-[390px] px-[20px] w-fit rounded-[40px] mx-auto text-[25px]">
                        {{ $t('registerNow') }}
                    </div>
                    <div class="mt-[17px] text-[17px] text-white">{{ $t('aggree') }}<span
                        class="text-[#F9A51A]" @click="$emit('showRule')">{{ $t('protocol') }}</span></div>
                </div>
                <div class="text-left">
                    <div class="flex mt-[22px] items-center" v-for="(item,index) in activeData.wordList" :key="index">
                        <img :src="item.logo" class="mr-[17px] w-[43px] h-[43px]"/>
                        <div>
                            <div class="text-white font-bold text-[20px]">{{ item.text1 }}</div>
                            <div class="text-[#A4A4A4] text-[16px]">{{ item.text2 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, getCurrentInstance} from "vue";
import qs from "qs";
import LangSelect from "@/components/LangSelect.vue";

// const emit = defineEmits(['showRule', 'btnClick'])
const props = defineProps({
    isIos: Boolean,
    index: Number,
})

const activeData = computed(() => {
    return dataList[props.index]
})

const {proxy} = getCurrentInstance()
const doDownload = () => {
    let parse = qs.parse(window.location.search.substring(1));
    let uCode = parse.u_code || '';
    window.open(`https://www.pp88hk.com/register?u_code=${uCode}`)
};
const contentStyle = computed(() => {
    let styleMap = {
        en_US: 'max-w-[660px] translate-x-[100px]',
        vi_VN: 'max-w-[660px] translate-x-[100px]',
        en_MAS: 'max-w-[660px] translate-x-[100px]',
        zh_CN: 'max-w-[540px] ',
        zh_TW: 'max-w-[540px]',
    }
    return styleMap[proxy.$i18n.locale]
})
const dataList = [
    {
        bg: new URL('@/assets/p/content1.webp', import.meta.url).href,
        extra: new URL('@/assets/p/eLine1.svg', import.meta.url).href,
        text1: proxy.$t('a1Text1'),
        text2: proxy.$t('a1Text2'),
        text3: proxy.$t('a1Text3'),
        text4: proxy.$t('a1Text4'),
        wordList: [
            {
                logo: new URL('@/assets/ICON1.svg', import.meta.url).href,
                text1: proxy.$t('a1Text5'),
                text2: proxy.$t('a1Text6'),
            },
            {
                logo: new URL('@/assets/ICON2.svg', import.meta.url).href,
                text1: proxy.$t('a1Text7'),
                text2: proxy.$t('a1Text8'),
            },
            {
                logo: new URL('@/assets/ICON3.svg', import.meta.url).href,
                text1: proxy.$t('a1Text9'),
                text2: proxy.$t('a1Text10'),
            },
        ]
    },
    {
        bg: new URL('@/assets/p/content3.webp', import.meta.url).href,
        extra: new URL('@/assets/p/eLine2.svg', import.meta.url).href,
        text1: proxy.$t('a2Text1'),
        text2: proxy.$t('a2Text2'),
        text3: proxy.$t('a2Text3'),
        text4: proxy.$t('a2Text4'),
        wordList: [
            {
                logo: new URL('@/assets/ICON4.svg', import.meta.url).href,
                text1: proxy.$t('a2Text5'),
                text2: proxy.$t('a2Text6'),
            },
            {
                logo: new URL('@/assets/ICON5.svg', import.meta.url).href,
                text1: proxy.$t('a2Text7'),
                text2: proxy.$t('a2Text8'),
            },
            {
                logo: new URL('@/assets/ICON6.svg', import.meta.url).href,
                text1: proxy.$t('a2Text9'),
                text2: proxy.$t('a2Text10'),
            },
            {
                logo: new URL('@/assets/ICON7.svg', import.meta.url).href,
                text1: proxy.$t('a2Text11'),
                text2: proxy.$t('a2Text12'),
            },
        ]
    },
    {
        bg: new URL('@/assets/p/content2.webp', import.meta.url).href,
        extra: new URL('@/assets/p/eLine3.svg', import.meta.url).href,
        text1: proxy.$t('a3Text1'),
        text2: proxy.$t('a3Text2'),
        text3: proxy.$t('a3Text3'),
        text4: proxy.$t('a3Text4'),
        wordList: [
            {
                logo: new URL('@/assets/ICON8.svg', import.meta.url).href,
                text1: proxy.$t('a3Text5'),
                text2: proxy.$t('a3Text6'),
            },
            {
                logo: new URL('@/assets/ICON9.svg', import.meta.url).href,
                text1: proxy.$t('a3Text7'),
                text2: proxy.$t('a3Text8'),
            },
            {
                logo: new URL('@/assets/ICON10.svg', import.meta.url).href,
                text1: proxy.$t('a3Text9'),
                text2: proxy.$t('a3Text10'),
            },
            {
                logo: new URL('@/assets/ICON11.svg', import.meta.url).href,
                text1: proxy.$t('a3Text11'),
                text2: proxy.$t('a3Text12'),
            },
            {
                logo: new URL('@/assets/ICON12.svg', import.meta.url).href,
                text1: proxy.$t('a3Text13'),
                text2: proxy.$t('a3Text14'),
            },
        ]
    },
    {
        bg: new URL('@/assets/p/content5.webp', import.meta.url).href,
        extra: new URL('@/assets/p/eLine4.svg', import.meta.url).href,
        text1: proxy.$t('a4Text1'),
        text2: proxy.$t('a4Text2'),
        text3: proxy.$t('a4Text3'),
        text4: proxy.$t('a4Text4'),
        wordList: [
            {
                logo: new URL('@/assets/ICON14.svg', import.meta.url).href,
                text1: proxy.$t('a4Text5'),
                text2: proxy.$t('a4Text6'),
            },
            {
                logo: new URL('@/assets/ICON15.svg', import.meta.url).href,
                text1: proxy.$t('a4Text7'),
                text2: proxy.$t('a4Text8'),
            },
            {
                logo: new URL('@/assets/ICON16.svg', import.meta.url).href,
                text1: proxy.$t('a4Text9'),
                text2: proxy.$t('a4Text10'),
            },
            {
                logo: new URL('@/assets/ICON17.svg', import.meta.url).href,
                text1: proxy.$t('a4Text11'),
                text2: proxy.$t('a4Text12'),
            },
            {
                logo: new URL('@/assets/ICON18.svg', import.meta.url).href,
                text1: proxy.$t('a4Text13'),
                text2: proxy.$t('a4Text14'),
            },
        ]
    },
    {
        bg: new URL('@/assets/p/content4.webp', import.meta.url).href,
        extra: new URL('@/assets/p/eLine5.svg', import.meta.url).href,
        text1: proxy.$t('a5Text1'),
        text2: proxy.$t('a5Text2'),
        text3: proxy.$t('a5Text3'),
        text4: proxy.$t('a5Text4'),
        wordList: [
            {
                logo: new URL('@/assets/ICON14.svg', import.meta.url).href,
                text1: proxy.$t('a5Text5'),
                text2: proxy.$t('a5Text6'),
            },
            {
                logo: new URL('@/assets/ICON15.svg', import.meta.url).href,
                text1: proxy.$t('a5Text7'),
                text2: proxy.$t('a5Text8'),
            },
            {
                logo: new URL('@/assets/ICON19.svg', import.meta.url).href,
                text1: proxy.$t('a5Text9'),
                text2: proxy.$t('a5Text10'),
            },
            {
                logo: new URL('@/assets/ICON20.svg', import.meta.url).href,
                text1: proxy.$t('a5Text11'),
                text2: proxy.$t('a5Text12'),
            },
        ]
    },
]
</script>
<style scoped>
.my-bg {
    background-image: url(@/assets/p/bg.webp);
}

.custom-font {
    font-family: 'HYYaKuHeiW';
}

.text3 {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
