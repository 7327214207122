import {createI18n} from 'vue-i18n'
import qs from "qs";
import {Locale} from "vant";
// import EN from './en/en'
// import CN from './ZH-CN/cn'

const message = {
    zh_CN: {
        // ...CN,
        confirm: '确认',
        iosDownloadConfirm:'请到 描述文件与 设备管理 中安装',
        registerNow: '立即注册',
        downloadNow: '普通下载',
        downloadNow2: '防封下载',
        aggree: '下载并同意',
        protocol: '《PP88规则与协议》',
        userAgreement: '用户协议',
        bigTitle: '一、遵守会员规范及法律规定：',
        bigTitleContent: '您了解您注册成为会员后，即可使用本公司所提供之各项服务（以下称本服务）。当会员使用本服务时，即表示除了同意遵守本服务条款外，还同意接受本公司对会员规范（包括各项游戏规则、球赛投注规则、公告及注意事项等）及相关法令规定之拘束，本公司所有产品与客户服务、软件系统、网络架构等相关业务事宜，皆由菲律宾政府认证机构所授权和监管之合法线上娱乐公司，使用本服务之会员请遵守使用者当地法令之许可，如有违反之情事恕非本服务之负责范围。',
        bigTitle2: '二、服务简介：',
        bigTitle2Content: '（一）本公司旨在创造一个『安全可靠』、『即时便利』、『公平公正』、『专业营运』的优质娱乐服务平台，强调的是让会员不受时空的限制，24小时随时上线就可以参与一个公​​平公正的游戏，亦可以享受到与世界各地玩家切磋的乐趣，我们秉持以客为尊的态度，不断开发创新及了解客户需求是本公司引以为傲的经营理念，冀望能创造出崭新的娱乐价值以及达到多方普及的目的，成为具有领导指标性的娱乐网站。',
        bigTitle2Content2: '（二）虚拟货币银行服务：本公司提供网路金流付费平台，你所购买的点数将可享受符合本公司平台的服务。若您使用虚伪不正之方式进行「储值」，本公司也将保留随时终止您会员资格及使用各项服务之权利。',
        bigTitle3: '三、真实登录义务：',
        bigTitle3Content: '基于本公司所提供之各项服务，您同意于注册时依注册申请程序所提示之项目，登录您本人正确、真实及完整之个人资料；当您的个人资料有异动时，请立即更新，以维持您个人资料之正确、真实及完整。如因您登录不实资料或冒用他人名义以致于侵害他人之权利或违法时，应自负法律责任；并同意您所提供之个人资料不实或个人资料有异动但没有更新以致于与原登录之资料不符时，本公司有权随时终止您的会员资格及使用各项会员服务之权利。',
        bigTitle4: '四、服务之停止与更改：',
        bigTitle4Content: '1.会员登录之资料不实。',
        bigTitle4Content2: '2.使用他人的名义申请本服务。',
        bigTitle4Content3: '3.违反游戏公平原。',
        bigTitle4Content4: '4.参与游戏或比赛时，故意钻研系统漏洞或利用游戏弱点以影响结果。',
        bigTitle4Content5: '5.违反本服务条款时。',
        bigTitle4Content6: '6.本公司不允许不公平的下注方式、双边下注、无风险下注及任何无风险解冻之下注方式，如有发现上述问题之玩家，本公司 有终止玩家账号使用之权利，如有任何争议问题，本公司有最终决定权。无论任何情形，就停止或更改服务或终止会员帐户服务所可能产生之困扰、不便或损害，本服务对任何会员或第三人均不负任何责任。',
        bigTitle5: '五、服务暂停或中断：',
        bigTitle5Content: '(一)本公司于下列情形之一时，得暂停或中断本服务之全部或一部，对于使用者不负担任何赔偿责任：',
        bigTitle5Content2: '1.对于本服务相关系统设备进行迁移、更换或维护时。',
        bigTitle5Content3: '2.因不可归责于本公司所造成服务停止或中断。',
        bigTitle5Content4: '3.因不可抗力所造成服务停止或中断。',
        bigTitle5Content5: '(二)如因本公司对于本网站相关系统设备进行迁移、更换或维护而必须暂停或中断全部或一部之服务时，本公司于暂停或中断前将以电子邮件通知或于本网站上公告。',
        bigTitle5Content6: '(三)对于本服务之暂停或中断，可能造成您使用上的不便、资料遗失或其他经济及时间上之损失，您平时应采取适当的防护措施，以保障您的权益。',
        bigTitle6: '六、保管义务：',
        bigTitle6Content: '会员有义务妥善保管在本网站之账号与密码，并为此组账号与密码登入系统后所进行之一切活动负责。为维护会员自身权益，请勿将账号与密码泄露或提供予第三人知悉，或出借或转让他人使用。',
        bigTitle7: '七、同意各项球赛及游戏规则：',
        bigTitle7Content: '为避免使用者于本平台投注球赛或游戏时产生争议，各项规则于游戏中心和球赛中心均有详细说明，请务必详细阅读本服务所定之各项规则，会员一经开始使用本服务，即被视为已接受所有之规定。',
        bigTitle8: '八、会员规范之增订及修改：',
        bigTitle8Content: '本服务​​条款如有增订或修改，您同意自该修订条款于本网站公告之时起受其拘束，本网站将不对会员个别通知。如您于公告后继续使用本服务，则视为您已经同意该修订条款。',
        bigTitle9: '九、隐私权声明：',
        bigTitle9Content: '我们致力为客户保护隐私并提供一个最安全的游戏平台，我们在此网站搜集的资料将会为您提供最卓越的服务，我们不会出卖或租赁您的个人资料给第三方，客户所提供的个人资料均经过SSL128加密技术处理，并储存在安全的、非公开的作业系统，对于有机会接触客户的个人资料的协助伙伴也必需遵守我们订立的隐私保密规则。',
        bigTitle10: '十、免责声明：',
        bigTitle10Content: '部份地区或国家法律尚未明定线上博彩的合法性问题，甚至某些地区或国家已明确规范线上博彩为非法行为。我们无意邀请任何人在这些地区或国家非法使用本服务平台。使用者必需确定在您所居住的地区或国家使用线上博彩是否合法，并负完全的责任，使用本服务之会员请遵守使用者当地法令之许可，如有违反之情事恕非本公司之负责范围。',
        a1Text1: '体育玩家投注首选平台',
        a1Text2: '体育投注返水',
        a1Text3: '超高返水，新用户即享1.5%',
        a1Text4: '西甲瓦伦西亚足球俱乐部赞助商，PP88值得你的信任！',
        a1Text5: '新用户即享1.5%返水',
        a1Text6: '你的每一场竞赛都有更高的机会取得更多的回报。',
        a1Text7: '官方赞助合作伙伴',
        a1Text8: '作为西甲瓦伦西亚足球俱乐部的官方赞助商，我们的信誉和专业无人能比。',
        a1Text9: '平台优势',
        a1Text10: '投注确认快，结算快，提款处理快，客服24小时响应快...',
        a2Text1: '老虎机活动超级丰富',
        a2Text2: '欢迎老虎机游戏爱好者加入PP88',
        a2Text3: '我们为你提供了无数的惊喜和奖励！',
        a2Text4: '立即加入我们，体验最丰富的老虎机活动，享受最大的乐趣和最丰富的奖励！',
        a2Text5: '双倍大奖',
        a2Text6: '在PP88，老虎机的大奖你有机会双倍赢取！每次的旋转都可能带给你惊喜和财富。',
        a2Text7: '免费投注机会',
        a2Text8: '我们提供给玩家免费的投注机会，让你无需花费就能感受到游戏的乐趣和刺激。',
        a2Text9: '每周红包',
        a2Text10: '在PP88，每一周我们都会有红包活动，只要活跃就有机会获得丰厚的奖励。',
        a2Text11: '天天活动，奖不停',
        a2Text12: '在PP88，每天都有新的活动等你来参与，奖励丰富，让你停不下来。',
        a3Text1: '超高返水 真人百家乐首选',
        a3Text2: '百家乐的玩家<br/>不可错过PP88',
        a3Text3: '',
        a3Text4: '我们为你提供了丰厚的返水优惠，公正的开奖结果，以及众多的奖励和活动！',
        a3Text5: '超高返水',
        a3Text6: '新用户注册即享返水0.6%，最高达1%，在PP88，每一次的游戏，你都有机会得到更多的回报。',
        a3Text7: '公平公正',
        a3Text8: '我们的开奖结果公平公正，全世界同步，让你无后顾之忧，只需专心享受游戏的乐趣。',
        a3Text9: 'VIP升级更容易',
        a3Text10: '在PP88，我们的VIP系统让你更容易升级，享受更多的特权和服务。',
        a3Text11: '彩金丰富',
        a3Text12: '我们提供丰富的彩金，给你的游戏增加更多的乐趣和奖励。',
        a3Text13: '周周领红包',
        a3Text14: '只要你活跃在PP88，每一周都有机会领取红包，奖励丰厚，让你的游戏更加有趣。',
        a4Text1: '大额度投注玩家的首选',
        a4Text2: '支持大额提款',
        a4Text3: '百万提款(30分钟)内完成在线娱乐平台',
        a4Text4: '作为西甲瓦伦西亚足球俱乐部的官方赞助，PP88无疑值得玩家的信赖。欢迎加入PP88，我们为你提供了最便捷的存取服务，更丰富的优惠活动。',
        a4Text5: '官方赞助商',
        a4Text6: 'PP88，作为西甲瓦伦西亚足球俱乐部的官方赞助商，我们的专业和信誉值得信赖。',
        a4Text7: '最火线上品牌',
        a4Text8: '在2023年，我们被评为香港最火的线上品牌，选择我们，就是选择最优质的服务和最好的游戏体验。',
        a4Text9: '存取快',
        a4Text10: '我们提供最快的存取服务，让你的游戏无间断，尽享快乐。',
        a4Text11: '大额提款无忧',
        a4Text12: '对于大额度投注的玩家，我们的大额提款服务让你无后顾之忧，让你的财富自由流动。',
        a4Text13: '优惠活动多',
        a4Text14: '我们提供众多优惠活动，让你在享受游戏的同时，也能得到更多的回报。',
        a5Text1: '最火线上娱乐品牌',
        a5Text2: '信誉良好的优质<br/>在线娱乐平台',
        a5Text3: '',
        a5Text4: '西甲瓦伦西亚足球俱乐部赞助商，PP88值得你的信任！',
        a5Text5: '西甲瓦伦西亚足球俱乐部赞助商',
        a5Text6: 'PP88，作为西甲瓦伦西亚足球俱乐部的官方赞助商，我们的专业和信誉值得信赖。',
        a5Text7: '最火线上品牌',
        a5Text8: '在2023年，我们被评为香港最火的线上娱乐品牌，选择我们，就是选择最优质的服务和最好的游戏体验。',
        a5Text9: '多样化的游戏',
        a5Text10: '无论你喜欢体育投注，真人百家乐，还是老虎机，我们都能满足你。我们有数千款老虎机游戏，让你有更多的选择和乐趣。',
        a5Text11: '丰厚的彩金',
        a5Text12: '在PP88，我们提供丰富的彩金，让你在享受游戏的同时，也能得到更多的回报。',
    },
    en_US: {
        // ...EN,
        confirm: 'Confirm',
        iosDownloadConfirm: 'Please install from Profiles & Device Management',
        registerNow: 'Register Now',
        downloadNow: 'Ordinary Download',
        downloadNow2: 'Anti-blocking Download',
        aggree: 'Download and Agree',
        protocol: '《PP88 Rules and Agreement》',
        userAgreement: 'User Agreement',
        bigTitle: 'First.Comply with membership regulations and legal requirements：',
        bigTitleContent: 'You understand that after you register as a member, you can use the various services provided by the company (hereinafter referred to as the service). When a member uses the service, it means that in addition to agreeing to abide by the terms of service, he also agrees to be bound by the company\'s membership regulations (including various game rules, ball game betting rules, announcements and precautions, etc.) and relevant laws and regulations. All the company\'s products and customer service, software systems, network architecture and other related business matters are legal online entertainment companies authorized and supervised by the Philippine government certification agency. Members who use this service should abide by the user\'s local laws and regulations. Violations are not the responsibility of this service.',
        bigTitle2: 'Second.Service Introduction：',
        bigTitle2Content: '(1) The company aims to create a high-quality entertainment service platform with "safety and reliability", "instant convenience", "fairness and justice", and "professional operation", emphasizing that members are not limited by time and space, and they can be online anytime 24 hours a day. You can participate in a fair and just game, and you can also enjoy the fun of learning with players from all over the world. We adhere to the attitude of customer-oriented, continuous development and innovation and understanding of customer needs are the company\'s proud business philosophy. It is hoped that it can create new entertainment value and achieve the purpose of popularization, and become a leading entertainment website.',
        bigTitle2Content2: '(2) USDT banking services: The company provides an online cash flow payment platform, and the points you purchase will be able to enjoy services that conform to the company\'s platform. If you use false or improper methods to "index value", the company will also reserve the right to terminate your membership and use of various services at any time.',
        bigTitle3: 'Third.Real login obligation：',
        bigTitle3Content: 'Based on the various services provided by the company, you agree to log in your correct, true and complete personal information according to the items prompted in the registration application procedure during registration; maintain the correctness, truthfulness and completeness of your personal data. If you infringe on the rights of others or violate the law because you log in false information or use the name of others, you should be responsible for your own legal responsibility; and agree that the personal information you provided is false or the personal information has changed but has not been updated, so that it is inconsistent with the original login. When the information does not match, the company reserves the right to terminate your membership and the right to use various membership services at any time.',
        bigTitle4: 'Fourth.Service stop and change：',
        bigTitle4Content: '1.Member login profile is unreal.',
        bigTitle4Content2: '2.Use another person\'s profile to apply for this service.',
        bigTitle4Content3: '3.Violation of game fairness',
        bigTitle4Content4: '4.When participating in games or competitions, deliberately exploit system vulnerabilities or exploit game weaknesses to affect the results.',
        bigTitle4Content5: '5.When the Terms of Service are violated',
        bigTitle4Content6: '6.The company does not allow unfair betting methods, bilateral betting, risk-free betting and any risk-free betting methods. If a player finds the above problems, the company has the right to terminate the use of the player\'s account. Disputes, the company has the final decision. In any case, this service shall not be liable to any member or third party for any trouble, inconvenience or damage that may arise from stopping or changing the service or terminating member account services.',
        bigTitle5: 'Fifth.Service Suspension or Interruption：',
        bigTitle5Content: '(1) The company may suspend or interrupt all or part of the service in one of the following circumstances, and will not be liable for any compensation to the user:',
        bigTitle5Content2: '1.When migrating, replacing or maintaining system equipment related to this service.',
        bigTitle5Content3: '2.The service is suspended or interrupted due to no responsibility of the company.',
        bigTitle5Content4: '3.Service suspension or interruption due to Force Majeure.',
        bigTitle5Content5: '(2)If the company has to suspend or interrupt all or part of the services due to the migration, replacement or maintenance of the relevant system equipment of this website, the company will notify by email or make an announcement on this website before the suspension or interruption.',
        bigTitle5Content6: '(3)The suspension or interruption of this service may cause inconvenience to your use, loss of data or other economic and time losses. You should usually take appropriate protective measures to protect your rights.',
        bigTitle6: 'Sixth.Custody obligations：',
        bigTitle6Content: 'Members are obliged to properly keep their account numbers and passwords on this website, and are responsible for all activities after logging into the system with this group of accounts and passwords. In order to protect the rights and interests of members, please do not disclose or provide the account number and password to a third party, or lend or transfer it to others for use.',
        bigTitle7: 'Seventh.Agree to the rules of the game and game：',
        bigTitle7Content: 'In order to avoid disputes when users bet on football games or games on this platform, all the rules are explained in deep in the game center and the game center. Please read the rules of this service in deep. All requirements are deemed to have been accepted.',
        bigTitle8: 'Eighth.Additions and revisions to the membership rules:',
        bigTitle8Content: 'If this Terms of Service is updated or revised, you agree to be bound by the revised terms from the time of announcement on this website, and this website will not notify members individually. If you continue to use the service after the announcement, you are deemed to have agreed to the revised terms.',
        bigTitle9: 'Ninth.Privacy Statement:',
        bigTitle9Content: 'We are committed to protecting the privacy of our customers and providing the safest gaming platform. The information we collect on this website will provide you with the best service. We will not sell or lease your personal information to third parties. Personal data are processed by SSL128 encryption technology and stored in a secure, non-public operating system. Partners who have access to customers\' personal data must also abide by our privacy and confidentiality rules.',
        bigTitle10: 'Tenth.Disclaimer:',
        bigTitle10Content: 'The laws of some regions or countries have not yet clearly defined the legality of online gambling, and even some regions or countries have clearly regulated online gambling as illegal. We do not intend to invite anyone to use this service platform illegally in these regions or countries. Users must determine whether it is legal to use online gambling in the region or country where you live, and take full responsibility. Members who use this service should abide by the user\'s local laws and regulations. If there is any violation, the company is not responsible. scope.',
        a1Text1: "Preferred Platform<br/> for Sports Betting",
        a1Text2: "Sports Betting Rebates",
        a1Text3: "Super High Rebates, New Users Enjoy 1.5%",
        a1Text4: "Official Sponsor of Valencia CF in La Liga, PP88 Earns Your Trust!",
        a1Text5: "New Users Enjoy 1.5% Rebates",
        a1Text6: "Every game you play has a higher chance of earning you more rewards.",
        a1Text7: "Official Sponsorship Partner",
        a1Text8: "As the official sponsor of Valencia CF in La Liga, our reputation and professionalism are unmatched.",
        a1Text9: "Platform Advantages",
        a1Text10: "Quick Bet Confirmation, Fast Settlement, Swift Withdrawals, 24-Hour Customer Support...",
        a2Text1: "Super Rich <br/>Slot Machine Activities",
        a2Text2: "Welcome Slot Machine Game Enthusiasts to Join PP88",
        a2Text3: "We offer countless surprises and rewards for you!",
        a2Text4: "Join us now and experience the richest slot machine activities, enjoy maximum fun, and receive the most abundant rewards!",
        a2Text5: "Double the Jackpot",
        a2Text6: "At PP88, you have a chance to double your winnings in slot machines! Every spin can bring you surprises and wealth.",
        a2Text7: "Free Betting Opportunities",
        a2Text8: "We provide players with free betting opportunities, allowing you to enjoy the fun and excitement of the game without spending money.",
        a2Text9: "Weekly Red Packets",
        a2Text10: "At PP88, we have red packet events every week, and as long as you're active, you have a chance to receive generous rewards.",
        a2Text11: "Daily Activities, Non-stop Rewards",
        a2Text12: "At PP88, there are new daily activities waiting for you to participate in, with rich rewards that will keep you engaged.",
        a3Text1: "Super High Cashback, First Choice for Live Baccarat",
        a3Text2: "Players of Baccarat<br/>Must Not Miss PP88",
        a3Text3: "",
        a3Text4: "We offer generous cashback promotions, fair lottery results, and numerous rewards and activities for you!",
        a3Text5: "Super High Cashback",
        a3Text6: "New users enjoy cashback of 0.6%, up to 1% upon registration at PP88. In PP88, every game gives you a chance to get more returns.",
        a3Text7: "Fair and Just",
        a3Text8: "Our lottery results are fair and just, synchronized worldwide, allowing you to enjoy the game without any worries, just focus on the fun.",
        a3Text9: "Easier VIP Upgrades",
        a3Text10: "At PP88, our VIP system makes it easier for you to upgrade and enjoy more privileges and services.",
        a3Text11: "Rich Jackpots",
        a3Text12: "We offer rich jackpots to add more fun and rewards to your game.",
        a3Text13: "Weekly Red Packet Rewards",
        a3Text14: "As long as you are active on PP88, you have a chance to receive red packet rewards every week, making your game more enjoyable.",
        a4Text1: "First Choice for High-Stakes Betting Players",
        a4Text2: "Supports High Withdrawal Limits",
        a4Text3: "Million-Dollar Withdrawals Completed Within 30 Minutes on the Online Entertainment Platform",
        a4Text4: "As the official sponsor of Valencia CF in La Liga, PP88 is undoubtedly worthy of players' trust. Join PP88, where we provide you with the most convenient access services and even more exciting promotions.",
        a4Text5: "Official Sponsor",
        a4Text6: "PP88, as the official sponsor of Valencia CF in La Liga, is known for its professionalism and reputation.",
        a4Text7: "Hottest Online Brand",
        a4Text8: "In 2023, we were rated as the hottest online brand in Hong Kong. Choosing us means selecting the best quality service and the finest gaming experience.",
        a4Text9: "Fast Access",
        a4Text10: "We offer the fastest access service, ensuring uninterrupted gaming and maximum enjoyment.",
        a4Text11: "Worry-Free High Withdrawals",
        a4Text12: "For high-stakes betting players, our high withdrawal service ensures your wealth flows freely without worries.",
        a4Text13: "Abundance of Promotions",
        a4Text14: "We provide numerous promotions, allowing you to enjoy the game while receiving even more rewards.",
        a5Text1: "The Hottest Online Entertainment Brand",
        a5Text2: "Reputable High-Quality Online <br/> Entertainment Platform",
        a5Text3: "",
        a5Text4: "Sponsor of Valencia CF in La Liga, PP88 is worthy of your trust!",
        a5Text5: "Sponsor of Valencia CF in La Liga",
        a5Text6: "PP88, as the official sponsor of Valencia CF in La Liga, is known for its professionalism and trustworthiness.",
        a5Text7: "The Hottest Online Brand",
        a5Text8: "In 2023, we were rated as the hottest online entertainment brand in Hong Kong. Choosing us means selecting the best quality service and the finest gaming experience.",
        a5Text9: "Diverse Games",
        a5Text10: "Whether you like sports betting, live baccarat, or slot machines, we can cater to your preferences. We have thousands of slot machine games, offering you more choices and fun.",
        a5Text11: "Generous Jackpots",
        a5Text12: "At PP88, we provide generous jackpots, allowing you to enjoy the game while also receiving more rewards."
    },
    zh_TW: {
        confirm: '確認',
        iosDownloadConfirm: '請從 描述文件與裝置管理 中安裝',
        registerNow: '立即註冊',
        downloadNow: '普通下載',
        downloadNow2: '防封下載',
        aggree: '下載並同意',
        protocol: '《PP88規則與協議》',
        //agreement.vue
        userAgreement: '用戶協議',
        bigTitle: '一、遵守會員規範及法律規定：',
        bigTitleContent: '您瞭解您註冊成為會員後，即可使用本公司所提供之各項服務（以下稱本服務）。當會員使用本服務時，即表示除了同意遵守本服務條款外，還同意接受本公司對會員規範（包括各項遊戲規則、球賽投注規則、公告及注意事項等）及相關法令規定之拘束，本公司所有產品與客戶服務、軟體系統、網路架構等相關業務事宜，皆由菲律賓政府認證機構所授權和監管之合法線上娛樂公司，使用本服務之會員請遵守使用者當地法令之許可，如有違反之情事恕非本服務之負責範圍。',
        bigTitle2: '二、服務簡介：',
        bigTitle2Content: '（一）本公司旨在創造一個『安全可靠』、『即時便利』、『公平公正』、『專業營運』的優質娛樂服務平臺，強調的是讓會員不受時空的限制，24小時隨時上線就可以參與一個公平公正的遊戲，亦可以享受到與世界各地玩家切磋的樂趣，我們秉持以客為尊的態度，不斷開發創新及瞭解客戶需求是本公司引以為傲的經營理念，冀望能創造出嶄新的娛樂價值以及達到多方普及的目的，成為具有領導指標性的娛樂網站。',
        bigTitle2Content2: '（二）虛擬貨幣銀行服務：本公司提供網路金流付費平臺，你所購買的點數將可享受符合本公司平臺的服務。若您使用虛偽不正之方式進行「儲值」，本公司也將保留隨時終止您會員資格及使用各項服務之權利。',
        bigTitle3: '三、真實登錄義務：',
        bigTitle3Content: '基於本公司所提供之各項服務，您同意於註冊時依註冊申請程式所提示之專案，登錄您本人正確、真實及完整之個人資料；當您的個人資料有異動時，請立即更新，以維持您個人資料之正確、真實及完整。如因您登錄不實資料或冒用他人名義以致於侵害他人之權利或違法時，應自負法律責任；並同意您所提供之個人資料不實或個人資料有異動但沒有更新以致於與原登錄之資料不符時，本公司有權隨時終止您的會員資格及使用各項會員服務之權利。',
        bigTitle4: '四、服務之停止與更改：',
        bigTitle4Content: '1.會員登錄之資料不實。',
        bigTitle4Content2: '2.使用他人的名義申請本服務。',
        bigTitle4Content3: '3.違反遊戲公平原。',
        bigTitle4Content4: '4.參與遊戲或比賽時，故意鑽研系統漏洞或利用遊戲弱點以影響結果。',
        bigTitle4Content5: '5.違反本服務條款時。',
        bigTitle4Content6: '6.本公司不允許不公平的下注方式、雙邊下注、無風險下注及任何無風險解凍之下注方式，如有發現上述問題之玩家，本公司 有終止玩家帳號使用之權利，如有任何爭議問題，本公司有最終決定權。無論任何情形，就停止或更改服務或終止會員帳戶服務所可能產生之困擾、不便或損害，本服務對任何會員或第三人均不負任何責任。',
        bigTitle5: '五、服務暫停或中斷：',
        bigTitle5Content: '(一)本公司於下列情形之一時，得暫停或中斷本服務之全部或一部，對於使用者不負擔任何賠償責任：',
        bigTitle5Content2: '1.對於本服務相關系統設備進行遷移、更換或維護時。',
        bigTitle5Content3: '2.因不可歸責於本公司所造成服務停止或中斷。',
        bigTitle5Content4: '3.因不可抗力所造成服務停止或中斷。',
        bigTitle5Content5: '(二)如因本公司對於本網站相關系統設備進行遷移、更換或維護而必須暫停或中斷全部或一部之服務時，本公司於暫停或中斷前將以電子郵件通知或於本網站上公告。',
        bigTitle5Content6: '(三)對於本服務之暫停或中斷，可能造成您使用上的不便、資料遺失或其他經濟及時間上之損失，您平時應採取適當的防護措施，以保障您的權益。',
        bigTitle6: '六、保管義務：',
        bigTitle6Content: '會員有義務妥善保管在本網站之帳號與密碼，並為此組帳號與密碼登入系統後所進行之一切活動負責。為維護會員自身權益，請勿將帳號與密碼洩露或提供予第三人知悉，或出借或轉讓他人使用。',
        bigTitle7: '七、同意各項球賽及遊戲規則：',
        bigTitle7Content: '為避免使用者於本平臺投注球賽或遊戲時產生爭議，各項規則於遊戲中心和球賽中心均有詳細說明，請務必詳細閱讀本服務所定之各項規則，會員一經開始使用本服務，即被視為已接受所有之規定。',
        bigTitle8: '八、會員規範之增訂及修改：',
        bigTitle8Content: '本服務條款如有增訂或修改，您同意自該修訂條款於本網站公告之時起受其拘束，本網站將不對會員個別通知。如您於公告後繼續使用本服務，則視為您已經同意該修訂條款。',
        bigTitle9: '九、隱私權聲明：',
        bigTitle9Content: '我們致力為客戶保護隱私並提供一個最安全的遊戲平臺，我們在此網站搜集的資料將會為您提供最卓越的服務，我們不會出賣或租賃您的個人資料給第三方，客戶所提供的個人資料均經過SSL128加密技術處理，並儲存在安全的、非公開的作業系統，對於有機會接觸客戶的個人資料的協助夥伴也必需遵守我們訂立的隱私保密規則。',
        bigTitle10: '十、免責聲明：',
        bigTitle10Content: '部份地區或國家法律尚未明定線上博彩的合法性問題，甚至某些地區或國家已明確規範線上博彩為非法行為。我們無意邀請任何人在這些地區或國家非法使用本服務平臺。使用者必需確定在您所居住的地區或國家使用線上博彩是否合法，並負完全的責任，使用本服務之會員請遵守使用者當地法令之許可，如有違反之情事恕非本公司之負責範圍。',
        a1Text1: "體育玩家投注首選平台",
        a1Text2: "體育投注返水",
        a1Text3: "超高返水，新用戶即享 1.5%",
        a1Text4: "西甲巴倫西亞足球俱樂部贊助商，PP88值得您的信任！",
        a1Text5: "新用戶即享1.5%返水",
        a1Text6: "您的每場比賽都有更高的機會獲得更多的回報。",
        a1Text7: "官方贊助合作夥伴",
        a1Text8: "作為西甲巴倫西亞足球俱樂部的官方贊助商，我們的信譽和專業無人能比。",
        a1Text9: "平台優勢",
        a1Text10: "投注確認快，結算快，提款處理快，客服24小時響應快...",
        a2Text1: "老虎機活動超級豐富",
        a2Text2: "歡迎老虎機游戲愛好者加入PP88",
        a2Text3: "我們為您提供無數驚喜和獎勵！",
        a2Text4: "立即加入我們，體驗最豐富的老虎機活動，享受最大的樂趣和最豐富的獎勵！",
        a2Text5: "雙倍大獎",
        a2Text6: "在PP88，老虎機的大獎您有機會雙倍贏取！每次的旋轉都可能帶給您驚喜和財富。",
        a2Text7: "免費投注機會",
        a2Text8: "我們提供給玩家免費的投注機會，讓您無需花費就能感受到遊戲的樂趣和刺激。",
        a2Text9: "每週紅包",
        a2Text10: "在PP88，每一週我們都會有紅包活動，只要活躍就有機會獲得豐厚的獎勵。",
        a2Text11: "天天活動，獎不停",
        a2Text12: "在PP88，每天都有新的活動等您來參與，獎勵豐富，讓您停不下來。",
        a3Text1: "超高返水 真人百家樂首選",
        a3Text2: "百家樂的玩家<br/>不可錯過PP88",
        a3Text3: "",
        a3Text4: "我們為您提供豐厚的返水優惠，公平的開獎結果，以及眾多的獎勵和活動！",
        a3Text5: "超高返水",
        a3Text6: "新用戶註冊即享返水0.6%，最高達1%，在PP88，每一次的遊戲，你都有機會得到更多的回報。",
        a3Text7: "公平公正",
        a3Text8: "我們的開獎結果公平公正，全世界同步，讓你無後顧之憂，只需專心享受遊戲的樂趣。",
        a3Text9: "VIP升級更容易",
        a3Text10: "在PP88，我們的VIP系統讓你更容易升級，享受更多的特權和服務。",
        a3Text11: "彩金豐富",
        a3Text12: "我們提供豐富的彩金，給你的遊戲增加更多的樂趣和獎勵。",
        a3Text13: "周周領紅包",
        a3Text14: "只要你活躍在PP88，每一周都有機會領取紅包，獎勵豐厚，讓你的遊戲更加有趣。",
        a4Text1: "高額投注玩家的首選",
        a4Text2: "支持高額提款",
        a4Text3: "百萬提款（30分鐘）內完成在線娛樂平台",
        a4Text4: "作為西甲瓦倫西亞足球俱樂部的官方贊助商，PP88無疑值得玩家的信賴。加入PP88，我們為您提供最便捷的訪問服務，以及更多刺激的促銷活動。",
        a4Text5: "官方贊助商",
        a4Text6: "PP88，作為西甲瓦倫西亞足球俱樂部的官方贊助商，以專業和信譽而著稱。",
        a4Text7: "最熱門的在線品牌",
        a4Text8: "在2023年，我們被評為香港最熱門的在線品牌",
        a4Text9: "存取快",
        a4Text10: "我們提供最快的存取服務，讓你的遊戲無間斷，盡享快樂。",
        a4Text11: "大額提款無憂",
        a4Text12: "對於大額度投注的玩家，我們的大額提款服務讓你無後顧之憂，讓你的財富自由流動。",
        a4Text13: "優惠活動多",
        a4Text14: "我們提供眾多優惠活動，讓你在享受遊戲的同時，也能得到更多的回報。",
        a5Text1: "最熱門的在线娱樂品牌",
        a5Text2: "信譽良好的高質量<br/>在线娱樂平台",
        a5Text3: "",
        a5Text4: "西甲瓦倫西亞足球俱樂部的贊助商，PP88值得您的信任！",
        a5Text5: "西甲瓦倫西亞足球俱樂部的贊助商",
        a5Text6: "PP88，作為西甲瓦倫西亞足球俱樂部的官方贊助商，以專業和信譽而著稱。",
        a5Text7: "最熱門的在線品牌",
        a5Text8: "在2023年，我們被評為香港最熱門的在線娛樂品牌。選擇我們意味著選擇最優質的服務和最好的遊戲體驗。",
        a5Text9: "多樣化的遊戲",
        a5Text10: "無論您喜歡體育博彩、真人百家樂還是老虎機，我們都可以滿足您的需求。我們擁有數千款老虎機遊戲，為您提供更多的選擇和樂趣。",
        a5Text11: "豐富的彩金",
        a5Text12: "在PP88，我們提供豐富的彩金，讓您在享受遊戲的同時也能獲得更多的回報。"
    },
    en_MAS: {
        confirm: 'Confirm',
        iosDownloadConfirm: 'Sila pasang dari Profil & Pengurusan Peranti',
        registerNow: 'Đăng ký ngay',
        downloadNow: 'Muat Turun Biasa',
        downloadNow2: 'Muat Turun Anti-Sekatan',
        aggree: 'Muat Turun dan Setuju',
        protocol: '《Peraturan dan Perjanjian PP88》',
        //agreement.vue
        userAgreement: 'Perjanjian Pengguna',
        bigTitle: '1. Mematuhi Peraturan Ahli dan Peraturan Undang-Undang:',
        bigTitleContent: 'Anda memahami bahawa setelah mendaftar sebagai ahli, anda boleh menggunakan semua perkhidmatan yang disediakan oleh syarikat kami (disebut sebagai "Perkhidmatan"). Apabila ahli menggunakan Perkhidmatan ini, ia bermakna selain bersetuju untuk mematuhi terma Perkhidmatan ini, ia juga bersetuju untuk terikat dengan peraturan ahli syarikat (termasuk peraturan permainan, peraturan pertaruhan bola, pengumuman dan nota penting lain) serta undang-undang yang berkaitan. Semua produk syarikat dan perkhidmatan pelanggan, sistem perisian, dan struktur rangkaian yang berkaitan dengan urusan perniagaan lain semuanya dikawal selia oleh badan pengesahan kerajaan Filipina dan diakui oleh persatuan antarabangsa. Ahli yang menggunakan Perkhidmatan ini dikehendaki mematuhi permit penggunaan undang-undang di tempat tinggal pengguna, jika ada sebarang kesalahan yang dilakukan tidak dibenarkan di bawah tanggungjawab Perkhidmatan ini.',
        bigTitle2: '2. Ringkasan Perkhidmatan:',
        bigTitle2Content: '(a) Syarikat kami bertujuan untuk mencipta satu platform perkhidmatan hiburan yang bermutu tinggi yang "selamat, boleh dipercayai", "segera dan mudah", "adil dan profesional" dan menekankan bahawa ahli tidak terhad oleh masa dan ruang, boleh log masuk pada bila-bila masa dan terlibat dalam permainan yang adil. Ahli juga boleh menikmati keseronokan bermain dengan pemain dari seluruh dunia. Kami memegang prinsip "pelanggan adalah segalanya", terus membangunkan inovasi dan memahami keperluan pelanggan adalah falsafah pengurusan syarikat kami yang kami banggakan. Kami berharap dapat mencipta nilai hiburan yang baru dan mencapai matlamat yang luas dalam mempromosikan laman web hiburan yang mempunyai penanda aras terkemuka.',
        bigTitle2Content2: '(b) Perkhidmatan bank mata wang maya: Syarikat kami menyediakan platform pembayaran rangkaian wang maya. Poin yang anda beli akan dapat menikmati perkhidmatan platform syarikat kami. Jika anda menggunakan cara penyelewengan untuk "menyimpan nilai", syarikat kami berhak pada bila-bila masa untuk menamatkan keahlian ahli dan hak untuk menggunakan semua perkhidmatan.',
        bigTitle3: '3. Kewajipan Log Masuk Sebenar:',
        bigTitle3Content: 'Berdasarkan perkhidmatan yang diberikan oleh syarikat kami, anda bersetuju untuk mendaftar dengan mengikuti panduan program permohonan pendaftaran dan memasukkan maklumat peribadi yang betul, tepat dan lengkap; dan anda dikehendaki untuk mengemas kini maklumat peribadi anda secepat mungkin jika terdapat sebarang perubahan untuk mengekalkan maklumat peribadi anda yang betul, tepat dan lengkap. Sekiranya anda menggunakan maklumat palsu atau menyamar sebagai orang lain dan menyebabkan penggunaan hak orang lain terjejas atau melanggar undang-undang, anda akan bertanggungjawab secara undang-undang; dan anda bersetuju bahawa jika maklumat peribadi yang anda berikan palsu atau tidak dikemas kini dengan segera dan menyebabkan ketidakserasian dengan maklumat log masuk asal, syarikat kami berhak untuk menghentikan keahlian anda serta menarik balik hak anda untuk menggunakan perkhidmatan keahlian.',
        bigTitle4: '4. Perkhidmatan Ditamatkan dan Diubah:',
        bigTitle4Content: '1. Maklumat log masuk ahli palsu.',
        bigTitle4Content2: '2. Menggunakan nama orang lain untuk memohon perkhidmatan ini.',
        bigTitle4Content3: '3. Melanggar prinsip keadilan permainan.',
        bigTitle4Content4: '4. Sengaja mencari-cari kelemahan sistem atau menggunakan kelemahan permainan untuk mempengaruhi hasil.',
        bigTitle4Content5: '5. Melanggar terma perkhidmatan ini.',
        bigTitle4Content6: '6. Syarikat kami tidak membenarkan cara pertaruhan yang tidak adil, pertaruhan dua pihak, pertaruhan tanpa risiko atau cara pertaruhan apa-apa yang boleh mengakibatkan pengeluaran wang tanpa risiko. Sekiranya terdapat pemain yang menghadapi masalah seperti yang dinyatakan di atas, syarikat kami berhak untuk menamatkan penggunaan akaun pemain tersebut. Sekiranya terdapat pertikaian apa-apa, syarikat kami berhak mengambil keputusan terakhir. Dalam apa jua keadaan, perkhidmatan ini tidak bertanggungjawab terhadap sebarang masalah, kesulitan atau kerosakan yang mungkin berlaku akibat penghentian atau perubahan perkhidmatan atau penghapusan perkhidmatan keahlian.',
        bigTitle5: '5. Perkhidmatan Dihentikan atau Dilucutkan Sementara:',
        bigTitle5Content: '(一) Dalam situasi berikut, syarikat boleh menangguhkan atau menghentikan seluruh atau sebahagian daripada perkhidmatan ini tanpa memikul sebarang tanggungjawab ganti rugi terhadap pengguna:',
        bigTitle5Content2: '1. Apabila sistem peralatan berkaitan dengan perkhidmatan ini dipindahkan, diganti atau diselenggara.',
        bigTitle5Content3: '2. Apabila perkhidmatan terhenti atau dihentikan disebabkan oleh faktor-faktor yang tidak boleh ditanggung oleh syarikat.',
        bigTitle5Content4: '3. Apabila perkhidmatan terhenti atau dihentikan disebabkan oleh faktor-faktor luar kuasa syarikat.',
        bigTitle5Content5: '(Dua) Jika syarikat perlu menangguhkan atau menghentikan seluruh atau sebahagian daripada perkhidmatan ini kerana memindahkan, mengganti atau menyelenggarakan sistem peralatan berkaitan dengan laman web ini, syarikat akan memberi notis melalui e-mel atau mengumumkannya di laman web ini sebelum menangguhkan atau menghentikan perkhidmatan.',
        bigTitle5Content6: '(Tiga) Dalam situasi di mana perkhidmatan ini terganggu atau dihentikan, ia mungkin menyebabkan kesulitan dalam penggunaan, kehilangan data atau kerugian lain dari segi ekonomi atau masa. Oleh itu, anda harus mengambil langkah-langkah berjaga-jaga yang sesuai untuk melindungi hak anda.',
        bigTitle6: 'Enam, Tanggungjawab Penjagaan:',
        bigTitle6Content: 'Ahli bertanggungjawab untuk memastikan akaun dan kata laluan di laman web ini dijaga dengan baik. Ahli bertanggungjawab atas semua aktiviti yang dilakukan selepas log masuk ke akaun menggunakan akaun dan kata laluan tersebut. Untuk menjaga hak ahli, sila jangan dedahkan atau berikan akaun dan kata laluan kepada pihak ketiga, atau pinjam atau alih guna kepada orang lain.',
        bigTitle7: 'Tujuh, Persetujuan Terhadap Peraturan Pertandingan dan Permainan:',
        bigTitle7Content: 'Untuk mengelakkan sebarang pertikaian semasa ahli bertaruh pada permainan atau pertandingan dalam platform ini, semua peraturan telah dijelaskan secara terperinci di Pusat Permainan dan Pusat Pertandingan. Sila pastikan anda membaca dengan teliti semua peraturan yang telah ditetapkan oleh perkhidmatan ini, apabila ahli memulakan penggunaan perkhidmatan ini, maka dianggap ahli telah menerima semua peraturan yang telah ditetapkan.',
        bigTitle8: 'Lapan, Penambahan dan Pengubahsuaian Aturan Ahli:',
        bigTitle8Content: 'Jika terdapat penambahan atau pengubahsuaian dalam terma perkhidmatan ini, anda bersetuju bahawa anda terikat dengan terma-terma tersebut dari tarikh dimaklumkan di laman web ini. Laman web ini tidak akan memberikan notis individu kepada ahli. Jika anda terus menggunakan perkhidmatan ini selepas dimaklumkan, maka dianggap anda telah bersetuju dengan perubahan tersebut.',
        bigTitle9: 'Sembilan, Penyataan Privasi:',
        bigTitle9Content: 'Kami berkomitmen untuk melindungi privasi pelanggan dan menyediakan platform permainan yang paling selamat. Maklumat yang dikumpulkan di laman web ini akan digunakan untuk menyediakan perkhidmatan terbaik kepada anda. Kami tidak akan menjual atau menyewa maklumat peribadi anda kepada pihak ketiga. Maklumat peribadi yang anda berikan diproses melalui teknologi enkripsi SSL128 dan disimpan dalam sistem yang selamat dan tidak dikongsi. Rakan kongsi yang berpeluang mengakses maklumat peribadi pelanggan juga mesti mematuhi peraturan privasi kami.',
        bigTitle10: 'Sepuluh, Penafian:',
        bigTitle10Content: 'Beberapa wilayah atau negara mungkin belum mengatur masalah legalitas perjudian online, bahkan beberapa wilayah atau negara sudah mengatur bahwa perjudian online sebagai tindakan ilegal. Kami tidak bermaksud mengundang siapa pun untuk menggunakan platform layanan ini di wilayah atau negara tersebut. Pengguna harus memastikan apakah penggunaan perjudian online di wilayah atau negara tempat tinggal Anda legal, dan bertanggung jawab sepenuhnya. Pengguna yang menggunakan layanan ini harus mematuhi izin hukum setempat, dan jika terjadi pelanggaran, itu bukan tanggung jawab perusahaan kami.',
        a1Text1: "Platform Pilihan<br/> Pertaruhan Sukan",
        a1Text2: "Pulangan Pertaruhan Sukan",
        a1Text3: "Pulangan Tinggi, Pengguna Baru Menikmati 1.5%",
        a1Text4: "Penaja Rasmi Valencia CF dalam La Liga, PP88 Layak Dikongsi Kepercayaan Anda!",
        a1Text5: "Pengguna Baru Nikmati Pulangan 1.5%",
        a1Text6: "Setiap permainan yang anda mainkan mempunyai peluang lebih tinggi untuk mendapatkan lebih banyak ganjaran.",
        a1Text7: "Rakan Penajaan Rasmi",
        a1Text8: "Sebagai rakan penaja rasmi Valencia CF dalam La Liga, reputasi dan profesionalisme kami tidak tertandingi.",
        a1Text9: "Kelebihan Platform",
        a1Text10: "Pengesahan Pertaruhan Pantas, Penyelesaian Pantas, Pengeluaran Cepat, Sokongan Pelanggan 24 Jam...",
        a2Text1: "Aktiviti Mesin <br/>Slot Sangat Kaya",
        a2Text2: "Selamat Datang Peminat Permainan Slot untuk Sertai PP88",
        a2Text3: "Kami menawarkan pelbagai kejutan dan ganjaran untuk anda!",
        a2Text4: "Sertai kami sekarang untuk mengalami aktiviti mesin slot yang paling melimpah, nikmati keseronokan maksimum, dan meraih ganjaran yang paling besar!",
        a2Text5: "Jackpot Ganda",
        a2Text6: "Di PP88, anda berpeluang memenangi jackpot ganda di mesin slot! Setiap putaran boleh membawa anda kejutan dan kekayaan.",
        a2Text7: "Peluang Pertaruhan Percuma",
        a2Text8: "Kami menyediakan peluang pertaruhan percuma kepada pemain, membolehkan anda menikmati keseronokan permainan tanpa perlu mengeluarkan wang.",
        a2Text9: "Sampul Merah Mingguan",
        a2Text10: "Di PP88, kami mengadakan acara sampul merah setiap minggu, dan selagi anda aktif, anda berpeluang menerima ganjaran yang besar.",
        a2Text11: "Aktiviti Harian, Ganjaran Tanpa Henti",
        a2Text12: "Di PP88, terdapat aktiviti baru untuk anda sertai setiap hari, dengan ganjaran yang melimpah untuk menarik perhatian anda.",
        a3Text1: "Cashback Super Tinggi, Pilihan Utama untuk Baccarat Langsung",
        a3Text2: "Pemain Baccarat Tidak Boleh Terlepas PP88",
        a3Text3: "",
        a3Text4: "Kami menawarkan promosi cashback yang cukup murah hati, keputusan loteri yang adil, dan banyak ganjaran dan aktiviti untuk anda!",
        a3Text5: "Cashback Super Tinggi",
        a3Text6: "Pengguna baru menikmati cashback sebanyak 0.6%, sehingga 1% semasa pendaftaran di PP88. Di PP88, setiap permainan memberi anda peluang untuk mendapatkan lebih banyak pulangan.",
        a3Text7: "Adil dan Saksama",
        a3Text8: "Keputusan loteri kami adalah adil dan saksama, disegerakkan di seluruh dunia, membolehkan anda menikmati permainan tanpa sebarang kebimbangan, hanya fokus pada keseronokan.",
        a3Text9: "Kemudahan Kenaikan VIP",
        a3Text10: "Di PP88, sistem VIP kami menjadikan kenaikan taraf lebih mudah dan membolehkan anda menikmati lebih banyak keistimewaan dan perkhidmatan.",
        a3Text11: "Jackpot Yang Kaya",
        a3Text12: "Kami menawarkan jackpot yang kaya untuk menambahkan lebih banyak keseronokan dan ganjaran kepada permainan anda.",
        a3Text13: "Ganjaran Sampul Merah Mingguan",
        a3Text14: "Selagi anda aktif di PP88, anda berpeluang untuk menerima ganjaran sampul merah setiap minggu, menjadikan permainan anda lebih menyeronokkan.",
        a4Text1: "Pilihan Pertama untuk Pemain Pertaruhan Besar",
        a4Text2: "Mendukung Had Pengeluaran Tinggi",
        a4Text3: "Pengeluaran Jutaan Selesai Dalam Tempoh 30 Minit di Platform Hiburan Dalam Talian",
        a4Text4: "Sebagai penaja rasmi Valencia CF dalam La Liga, PP88 tanpa ragu adalah tempat yang patut dipercayai oleh pemain. Sertai PP88, di mana kami menyediakan perkhidmatan akses yang paling mudah dan promosi yang lebih menarik.",
        a4Text5: "Penaja Rasmi",
        a4Text6: "PP88, sebagai penaja rasmi Valencia CF dalam La Liga, dikenali kerana profesionalisme dan reputasi kami.",
        a4Text7: "Jenama Dalam Talian Paling Popular",
        a4Text8: "Pada tahun 2023, kami dinilai sebagai jenama dalam talian paling popular di Hong Kong. Memilih kami bermakna memilih perkhidmatan berkualiti terbaik dan pengalaman bermain yang terbaik.",
        a4Text9: "Akses Pantas",
        a4Text10: "Kami menawarkan perkhidmatan akses terpantas, memastikan permainan berjalan tanpa gangguan dan keseronokan maksimum.",
        a4Text11: "Pengeluaran Besar Tanpa Keresahan",
        a4Text12: "Untuk pemain pertaruhan besar, perkhidmatan pengeluaran tinggi kami memastikan kekayaan anda mengalir bebas tanpa keresahan.",
        a4Text13: "Pelbagai Promosi",
        a4Text14: "Kami menyediakan pelbagai promosi, membolehkan anda menikmati permainan sambil menerima lebih banyak ganjaran.",
        a5Text1: "Jenama Hiburan Dalam Talian Paling Popular",
        a5Text2: "Platform Hiburan Dalam Talian Berkualiti Berpeluang Tinggi",
        a5Text3: "",
        a5Text4: "Penaja Valencia CF dalam La Liga, PP88 adalah tempat yang patut dipercayai!",
        a5Text5: "Penaja Valencia CF dalam La Liga",
        a5Text6: "PP88, sebagai penaja rasmi Valencia CF dalam La Liga, dikenali kerana profesionalisme dan kebolehpercayaan kami.",
        a5Text7: "Jenama Dalam Talian Paling Hangat",
        a5Text8: "Pada tahun 2023, kami dinilai sebagai jenama hiburan dalam talian paling hangat di Hong Kong. Memilih kami bermakna memilih perkhidmatan berkualiti terbaik dan pengalaman bermain yang terbaik.",
        a5Text9: "Pelbagai Permainan",
        a5Text10: "Sama ada anda suka pertaruhan sukan, baccarat langsung, atau mesin slot, kami boleh memenuhi pilihan anda. Kami memiliki ribuan permainan mesin slot, menawarkan lebih banyak pilihan dan keseronokan.",
        a5Text11: "Jackpot Murah Hati",
        a5Text12: "Di PP88, kami menyediakan jackpot yang murah hati, membolehkan anda menikmati permainan sambil menerima lebih banyak ganjaran."

    },
    vi_VN: {
        confirm: 'Xác nhận',
        iosDownloadConfirm: 'Vui lòng cài đặt từ Hồ sơ & Quản lý Thiết bị',
        registerNow: 'Đăng ký ngay',
        downloadNow: 'Tải Xuống Thông Thường',
        downloadNow2: 'Tải Xuống Chống Chặn',
        aggree: 'Tải xuống và đồng ý',
        protocol: '《PP88 Qui tắc và Thỏa thuận》',
        userAgreement: 'Thỏa thuận người dùng',
        bigTitle: 'Mục 1: Tuân thủ Quy định Thành viên và Luật pháp:',
        bigTitleContent: 'Bạn hiểu rằng sau khi đăng ký trở thành thành viên, bạn có thể sử dụng các dịch vụ được cung cấp bởi công ty chúng tôi (sau đây được gọi là "Dịch vụ"). Khi thành viên sử dụng Dịch vụ này, điều đó ngụ ý rằng ngoài việc đồng ý tuân theo các điều khoản Dịch vụ này, bạn cũng đồng ý tuân theo các Quy định Thành viên của công ty (bao gồm các quy định về trò chơi, quy tắc đặt cược trận đấu, thông báo và lưu ý, vv.) và các quy định về luật pháp liên quan. Tất cả sản phẩm và dịch vụ của công ty chúng tôi, hệ thống phần mềm, kiến trúc mạng và các vấn đề kinh doanh liên quan đều được ủy quyền và giám sát bởi cơ quan chứng nhận của chính phủ Philippines. Thành viên sử dụng Dịch vụ này vui lòng tuân theo sự cho phép của luật pháp địa phương của họ và nếu vi phạm, sẽ không thuộc phạm vi trách nhiệm của Dịch vụ này.',
        bigTitle2: 'Mục 2: Giới thiệu về Dịch vụ:',
        bigTitle2Content: '(1) Công ty của chúng tôi nhằm mục tiêu tạo ra một nền tảng dịch vụ giải trí chất lượng cao "An toàn, Đáng tin cậy", "Tức thời và Tiện lợi", "Công bằng và Công bằng", "Chuyên nghiệp", nhấn mạnh vào việc cho phép các thành viên không bị ràng buộc bởi thời gian và không gian, có thể tham gia trò chơi công bằng và công bằng 24/7, cũng như tham gia vào niềm vui thử thách với người chơi từ khắp nơi trên thế giới. Chúng tôi theo đuổi tinh thần "Khách hàng là số 1", không ngừng phát triển và đổi mới cũng như hiểu rõ nhu cầu của khách hàng, đây là triết lý kinh doanh mà chúng tôi tự hào. Hy vọng chúng tôi có thể tạo ra giá trị giải trí mới và đạt được mục tiêu thông dụng đa phương, trở thành một trang web giải trí dẫn đầu với các chỉ số dẫn đầu.',
        bigTitle2Content2: '(2) Dịch vụ Ngân hàng Tiền ảo: Công ty của chúng tôi cung cấp nền tảng thanh toán trực tuyến, điểm số bạn mua sẽ được hưởng các dịch vụ của nền tảng công ty chúng tôi. Nếu bạn sử dụng cách thức nạp tiền không chính xác hoặc gian lận, công ty của chúng tôi có quyền ngừng thành viên và quyền sử dụng dịch vụ.',
        bigTitle3: 'Mục 3: Trách nhiệm đăng nhập thật sự:',
        bigTitle3Content: 'Dựa trên các dịch vụ được cung cấp bởi công ty của chúng tôi, bạn đồng ý rằng vào thời điểm đăng ký, bạn sẽ đăng nhập thông tin cá nhân của bạn đúng, thật và hoàn chỉnh theo những mục mà quy trình đăng ký yêu cầu. Khi thông tin cá nhân của bạn có sự thay đổi, hãy cập nhật ngay lập tức để duy trì thông tin cá nhân của bạn đúng, thật và hoàn chỉnh. Nếu bạn đăng nhập với thông tin cá nhân không chính xác hoặc mạo danh người khác, gây vi phạm quyền của người khác hoặc vi phạm pháp luật, bạn sẽ phải chịu trách nhiệm theo luật pháp. Bạn cũng đồng ý rằng nếu bạn cung cấp thông tin cá nhân không chính xác hoặc nếu thông tin cá nhân của bạn có sự thay đổi nhưng bạn không cập nhật, công ty của chúng tôi có quyền ngừng tư cách thành viên và quyền sử dụng các dịch vụ thành viên.',
        bigTitle4: 'Mục 4: Dừng và Thay đổi Dịch vụ:',
        bigTitle4Content: '1. Thông tin đăng nhập của thành viên không chính xác.',
        bigTitle4Content2: '2. Sử dụng tên người khác để đăng ký dịch vụ này.',
        bigTitle4Content3: '3. Vi phạm nguyên tắc công bằng của trò chơi.',
        bigTitle4Content4: '4. Khi tham gia trò chơi hoặc cuộc thi, cố ý tìm hiểu lỗ hổng hệ thống hoặc tận dụng điểm yếu của trò chơi để ảnh hưởng đến kết quả.',
        bigTitle4Content5: '5. Vi phạm các điều khoản dịch vụ này.',
        bigTitle4Content6: '6. Công ty của chúng tôi không cho phép cách đặt cược không công bằng, cách đặt cược hai bên, cách đặt cược không rủi ro và bất kỳ cách đặt cược không rủi ro nào khác. Nếu có người chơi phát hiện ra các vấn đề nêu trên, công ty của chúng tôi có quyền ngừng tài khoản của người chơi. Trong bất kỳ trường hợp nào, Dịch vụ này sẽ không chịu trách nhiệm đối với sự phiền hà, bất tiện hoặc thiệt hại nào có thể phát sinh từ việc tạm dừng hoặc thay đổi Dịch vụ hoặc chấm dứt quyền truy cập dịch vụ thành viên.',
        bigTitle5: 'Mục 5: Tạm dừng hoặc Gián đoạn Dịch vụ:',
        bigTitle5Content: '(1) Công ty của chúng tôi có thể tạm dừng hoặc gián đoạn toàn bộ hoặc một phần của Dịch vụ trong các tình huống sau đây mà không chịu trách nhiệm bồi thường cho người sử dụng:',
        bigTitle5Content2: '1. Khi di chuyển, thay đổi hoặc bảo trì hệ thống và thiết bị liên quan đến Dịch vụ.',
        bigTitle5Content3: '2. Khi Dịch vụ bị tạm dừng hoặc gián đoạn do lý do ngoài sức kiểm soát của công ty chúng tôi.',
        bigTitle5Content4: '3. Khi Dịch vụ bị tạm dừng hoặc gián đoạn do lý do ngoại vi.',
        bigTitle5Content5: '(2) Khi công ty của chúng tôi cần tạm dừng hoặc gián đoạn toàn bộ hoặc một phần của Dịch vụ để chuyển đổi, thay đổi hoặc bảo trì hệ thống và thiết bị liên quan, công ty của chúng tôi sẽ thông báo qua email hoặc thông báo trên trang web trước khi tạm dừng hoặc gián đoạn.',
        bigTitle5Content6: '(3) Đối với việc tạm dừng hoặc gián đoạn Dịch vụ, có thể gây phiền hà, mất dữ liệu hoặc thiệt hại kinh tế và thời gian cho bạn. Bạn nên thực hiện các biện pháp bảo vệ thích hợp để đảm bảo quyền lợi của bạn.',
        bigTitle6: 'Sáu, Trách nhiệm bảo quản:',
        bigTitle6Content: 'Thành viên có trách nhiệm bảo quản tài khoản và mật khẩu trên trang web này một cách cẩn thận và chịu trách nhiệm cho tất cả các hoạt động sau khi đăng nhập bằng tài khoản và mật khẩu này. Để bảo vệ quyền lợi của thành viên, đừng tiết lộ hoặc cung cấp tài khoản và mật khẩu cho bất kỳ người thứ ba nào hoặc cho mượn hoặc chuyển nhượng cho người khác sử dụng.',
        bigTitle7: 'Bảy, Đồng ý với các quy tắc trò chơi và trận đấu:',
        bigTitle7Content: 'Để tránh xảy ra tranh cãi khi đặt cược trận đấu hoặc trò chơi trên nền tảng này, các quy tắc cụ thể được mô tả chi tiết tại Trung tâm trò chơi và Trung tâm trận đấu. Xin vui lòng đọc kỹ các quy tắc quy định trong dịch vụ này. Ngay khi thành viên bắt đầu sử dụng dịch vụ này, họ được coi là đã chấp nhận tất cả các quy định.',
        bigTitle8: 'Tám, Sửa đổi và bổ sung quy định thành viên:',
        bigTitle8Content: 'Nếu có bất kỳ sửa đổi hoặc bổ sung nào cho các điều khoản dịch vụ này, bạn đồng ý rằng bạn sẽ tuân theo những sửa đổi này kể từ thời điểm được công bố trên trang web này, và trang web này sẽ không thông báo riêng cho từng thành viên. Nếu bạn tiếp tục sử dụng dịch vụ sau khi sửa đổi được công bố, điều này sẽ được coi là bạn đã chấp nhận các điều khoản sửa đổi.',
        bigTitle9: 'Chín, Tuyên bố về quyền riêng tư:',
        bigTitle9Content: 'Chúng tôi cam kết bảo vệ quyền riêng tư của khách hàng và cung cấp một nền tảng trò chơi an toàn nhất. Thông tin mà chúng tôi thu thập trên trang web này sẽ được sử dụng để cung cấp dịch vụ xuất sắc nhất cho bạn. Chúng tôi sẽ không bán hoặc cho thuê thông tin cá nhân của bạn cho bên thứ ba nào. Thông tin cá nhân mà khách hàng cung cấp sẽ được xử lý bằng công nghệ mã hóa SSL128 và lưu trữ trong hệ thống làm việc an toàn và không công khai. Các đối tác có cơ hội tiếp xúc với thông tin cá nhân của khách hàng cũng phải tuân thủ các quy định về bảo mật riêng tư mà chúng tôi đã thiết lập.',
        bigTitle10: 'Mười, Tuyên bố miễn trách nhiệm:',
        bigTitle10Content: 'Một số khu vực hoặc quốc gia vẫn chưa rõ ràng về vấn đề tính hợp pháp của cá cược trực tuyến, thậm chí trong một số khu vực hoặc quốc gia, cá cược trực tuyến đã được quy định là hoạt động bất hợp pháp. Chúng tôi không mời gì cả người dùng tham gia vào nền tảng dịch vụ trong những khu vực hoặc quốc gia này. Người dùng phải tự xác định tính hợp pháp của cá cược trực tuyến tại khu vực hoặc quốc gia mà họ đang sinh sống và chịu hoàn toàn trách nhiệm. Các thành viên sử dụng dịch vụ này phải tuân theo luật pháp địa phương của họ, và nếu vi phạm, thì chúng tôi sẽ không chịu trách nhiệm.',
        a1Text1: 'Nền tảng cá cược thể thao hàng đầu cho người chơi',
        a1Text2: 'Hoàn tiền cá cược thể thao',
        a1Text3: 'Hoàn tiền cực cao, người dùng mới ngay lập tức nhận 1.5%',
        a1Text4: 'Đối tác tài trợ của Câu lạc bộ Bóng đá Valencia La Liga, PP88 xứng đáng được bạn tin tưởng!',
        a1Text5: 'Ngay lập tức nhận 1,5% hoàn tiền cho người dùng mới',
        a1Text6: 'Mỗi trận đấu của bạn đều có cơ hội lớn để đạt được nhiều phần thưởng hơn.',
        a1Text7: 'Đối tác tài trợ chính thức',
        a1Text8: 'Là đối tác tài trợ chính thức của Câu lạc bộ Bóng đá Valencia La Liga, uy tín và chuyên nghiệp của chúng tôi không ai sánh bằng.',
        a1Text9: 'Ưu điểm của nền tảng',
        a1Text10: 'Xác nhận cược nhanh chóng, thanh toán nhanh, xử lý rút tiền nhanh chóng, hỗ trợ khách hàng 24/7 nhanh chóng...',
        a2Text1: 'Sự kiện máy đánh bạc đa dạng và phong phú',
        a2Text2: 'Chào mừng những người yêu thích trò chơi máy đánh bạc tham gia PP88',
        a2Text3: 'Chúng tôi mang đến cho bạn nhiều bất ngờ và phần thưởng!',
        a2Text4: 'Tham gia ngay hôm nay, trải nghiệm các sự kiện máy đánh bạc đa dạng nhất, thưởng thức niềm vui lớn nhất và phần thưởng phong phú nhất!',
        a2Text5: 'Giải thưởng gấp đôi',
        a2Text6: 'Tại PP88, bạn có cơ hội giành giải thưởng gấp đôi trong trò chơi máy đánh bạc! Mỗi lần quay đều có thể mang lại sự bất ngờ và tài sản cho bạn.',
        a2Text7: 'Cơ hội cá cược miễn phí',
        a2Text8: 'Chúng tôi cung cấp cơ hội cá cược miễn phí cho người chơi, giúp bạn trải nghiệm niềm vui và thách thức của trò chơi mà không cần trả tiền.',
        a2Text9: 'Thưởng hàng tuần',
        a2Text10: 'Tại PP88, chúng tôi có các sự kiện thưởng hàng tuần, chỉ cần bạn tham gia tích cực, bạn có cơ hội nhận được phần thưởng hậu hĩnh.',
        a2Text11: 'Sự kiện hàng ngày, không ngừng thưởng',
        a2Text12: 'Tại PP88, mỗi ngày đều có sự kiện mới đang chờ bạn tham gia, với phần thưởng phong phú, giúp bạn không ngừng chơi và nhận thưởng.',
        a3Text1: 'Hoàn tiền cực cao, lựa chọn số 1 cho người chơi bài Baccarat thực tế',
        a3Text2: 'Người chơi bài Baccarat không nên bỏ lỡ PP88',
        a3Text3: '',
        a3Text4: 'Chúng tôi mang đến cho bạn ưu đãi hoàn tiền hậu hĩnh, kết quả công bằng của trò chơi, cùng nhiều phần thưởng và sự kiện!',
        a3Text5: 'Hoàn tiền cực cao',
        a3Text6: 'Ngay khi đăng ký, người dùng mới nhận ngay hoàn tiền 0,6%, tối đa lên đến 1%. Tại PP88, mỗi trò chơi, bạn luôn có cơ hội nhận được nhiều phần thưởng hơn.',
        a3Text7: 'Công bằng và minh bạch',
        a3Text8: 'Kết quả của chúng tôi luôn được công bố một cách công bằng và minh bạch, đồng bộ trên toàn thế giới, giúp bạn yên tâm và tập trung vào niềm vui của trò chơi.',
        a3Text9: 'Dễ dàng nâng cấp VIP',
        a3Text10: 'Tại PP88, hệ thống VIP của chúng tôi giúp bạn dễ dàng nâng cấp, cùng nhiều đặc quyền và dịch vụ hơn.',
        a3Text11: 'Phần thưởng hậu hĩnh',
        a3Text12: 'Chúng tôi cung cấp nhiều phần thưởng hậu hĩnh, mang đến thêm niềm vui và phần thưởng cho trò chơi của bạn.',
        a3Text13: 'Nhận hồi về hàng tuần',
        a3Text14: 'Chỉ cần bạn tích cực tham gia tại PP88, hàng tuần bạn có cơ hội nhận hồi về, với phần thưởng hậu hĩnh, làm cho trò chơi của bạn trở nên thú vị hơn.',
        a4Text1: 'Lựa chọn hàng đầu cho người chơi cá cược số lớn',
        a4Text2: 'Hỗ trợ rút tiền số lớn',
        a4Text3: 'Rút tiền hàng triệu đồng (trong vòng 30 phút) trên nền tảng giải trí trực tuyến',
        a4Text4: 'Là đối tác tài trợ chính thức của Câu lạc bộ Bóng đá Valencia La Liga, PP88 không có lý do gì để người chơi không tin tưởng. Chào mừng bạn tham gia PP88, chúng tôi mang đến cho bạn dịch vụ nạp rút tiền tiện lợi nhất và nhiều ưu đãi hơn.',
        a4Text5: 'Đối tác tài trợ chính thức',
        a4Text6: 'PP88, với tư cách là đối tác tài trợ chính thức của Câu lạc bộ Bóng đá Valencia La Liga, sự chuyên nghiệp và uy tín của chúng tôi là đáng tin cậy.',
        a4Text7: 'Thương hiệu trực tuyến nổi tiếng nhất',
        a4Text8: 'Năm 2023, chúng tôi đã được bình chọn là thương hiệu trực tuyến nổi tiếng nhất tại Hồng Kông. Lựa chọn chúng tôi là lựa chọn dịch vụ chất lượng nhất và trải nghiệm trò chơi tốt nhất.',
        a4Text9: 'Nạp rút nhanh chóng',
        a4Text10: 'Chúng tôi cung cấp dịch vụ nạp rút nhanh chóng nhất, giúp bạn trải qua trò chơi mà không bị gián đoạn và tận hưởng niềm vui.',
        a4Text11: 'Rút tiền số lớn mà không lo',
        a4Text12: 'Đối với người chơi đặt cược số lớn, dịch vụ rút tiền số lớn của chúng tôi giúp bạn không phải lo lắng, cho phép tài sản của bạn luôn tự do lưu thông.',
        a4Text13: 'Nhiều ưu đãi hơn',
        a4Text14: 'Chúng tôi cung cấp nhiều chương trình ưu đãi để bạn không chỉ tham gia trò chơi mà còn nhận được nhiều phần thưởng hơn.',
        a5Text1: 'Thương hiệu giải trí trực tuyến nổi tiếng nhất',
        a5Text2: 'Nền tảng giải trí trực tuyến chất lượng với danh tiếng uy tín',
        a5Text3: '',
        a5Text4: 'Là đối tác tài trợ chính thức của Câu lạc bộ Bóng đá Valencia La Liga, PP88 không có lý do gì để người chơi không tin tưởng. Chào mừng bạn tham gia PP88, chúng tôi mang đến cho bạn dịch vụ chất lượng và nhiều trò chơi thú vị.',
        a5Text5: 'Đối tác tài trợ chính thức của Câu lạc bộ Bóng đá Valencia La Liga',
        a5Text6: 'PP88, với tư cách là đối tác tài trợ chính thức của Câu lạc bộ Bóng đá Valencia La Liga, sự chuyên nghiệp và uy tín của chúng tôi là đáng tin cậy.',
        a5Text7: 'Thương hiệu trực tuyến nổi tiếng nhất',
        a5Text8: 'Năm 2023, chúng tôi đã được bình chọn là thương hiệu giải trí trực tuyến nổi tiếng nhất tại Hồng Kông. Lựa chọn chúng tôi là lựa chọn dịch vụ chất lượng nhất và trải nghiệm trò chơi tốt nhất.',
        a5Text9: 'Nhiều trò chơi đa dạng',
        a5Text10: 'Cho dù bạn thích cá cược thể thao, bài Baccarat trực tiếp, hoặc máy đánh bạc, chúng tôi có thể đáp ứng nhu cầu của bạn. Chúng tôi có hàng ngàn trò chơi máy đánh bạc để bạn lựa chọn và tận hưởng.',
        a5Text11: 'Phần thưởng hậu hĩnh',
        a5Text12: 'Tại PP88, chúng tôi cung cấp nhiều phần thưởng hậu hĩnh, giúp bạn tận hưởng niềm vui trò chơi và nhận được nhiều phần thưởng hơn.'
    }
}

let parse = qs.parse(window.location.search.substring(1));
let lang = parse.lang;
if (!lang) lang = 'zh_CN';
const i18n = createI18n({
    locale: lang, // 设置语言类型
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages: message,
})

const messages = {
    'zh-CN': {
        vanPicker: {
            confirm: '关闭', // 将'确认'修改为'关闭'
        },
    },
};

Locale.add(messages);

export default i18n
