import {createApp} from 'vue'
import App from './App.vue'
import i18n from "@/script/i18n";
import unoverlay from "unoverlay-vue";
import 'uno.css'
import '@/assets/style.css'
import VueMobileDetection from "vue-mobile-detection";
import {Drawer} from 'ant-design-vue';
import {Toast} from "vant";

// import VConsole from "vconsole";
// import { initPlugin } from 'vue-vconsole-devtools'
// initPlugin(new VConsole()); // 需要在创建Vue根实例前调用
const app = createApp(App);
window.app = app;

app.use(unoverlay).use(VueMobileDetection).use(Drawer).use(Toast).use(i18n).mount('#app')
