<template>
    <!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <MobilePage v-if="$isMobile()" :index="targetIndex" @showRule="visible=true" ></MobilePage>
    <PcPage v-else :index="targetIndex" @showRule="visible=true"></PcPage>
    <van-dialog v-model:show="visible" :title="$t('userAgreement')" :confirm-button-text="$t('confirm')" :class="$isMobile()?'w-[90vw]':'w-[40vw]'">
        <div class="text-[14px] text-white px-[10px] py-[5px] max-h-[60vh]" style="overflow: auto;">
            <h5>{{ $t('bigTitle') }}</h5>
            <p>{{ $t('bigTitleContent') }}</p>
            <h5>{{ $t('bigTitle2') }}</h5>
            <p>{{ $t('bigTitle2Content') }}</p>
            <p>{{ $t('bigTitle2Content2') }}</p>
            <h5>{{ $t('bigTitle3') }}</h5>
            <p>{{ $t('bigTitle3Content') }}</p>
            <h5>{{ $t('bigTitle4') }}</h5>
            <p>
                <span>{{ $t('bigTitle4Content') }}</span>
                <span>{{ $t('bigTitle4Content2') }}</span>
                <span>{{ $t('bigTitle4Content3') }}</span>
                <span>{{ $t('bigTitle4Content4') }}</span>
                <span>{{ $t('bigTitle4Content5') }}</span>
                <span>{{ $t('bigTitle4Content6') }}</span>
            </p>
            <h5>{{ $t('bigTitle5') }}</h5>
            <p>
                <span>{{ $t('bigTitle5Content') }}</span>
                <span>{{ $t('bigTitle5Content2') }}</span>
                <span>{{ $t('bigTitle5Content3') }}</span>
                <span>{{ $t('bigTitle5Content4') }}</span>
                <span>{{ $t('bigTitle5Content5') }}</span>
                <span>{{ $t('bigTitle5Content6') }}</span>
            </p>
            <h5>{{ $t('bigTitle6') }}</h5>
            <p>{{ $t('bigTitle6Content') }}</p>
            <h5>{{ $t('bigTitle7') }}</h5>
            <p>{{ $t('bigTitle7Content') }}</p>
            <h5>{{ $t('bigTitle8') }}</h5>
            <p>{{ $t('bigTitle8Content') }}</p>
            <h5>{{ $t('bigTitle9') }}</h5>
            <p>{{ $t('bigTitle9Content') }}</p>
            <h5>{{ $t('bigTitle10') }}</h5>
            <p>{{ $t('bigTitle10Content') }}</p>
        </div>
    </van-dialog>
</template>

<script setup>
import {Dialog as VanDialog} from "vant";
import 'vant/es/dialog/style';
import {getCurrentInstance, onMounted, ref} from "vue";
import MobilePage from "@/components/MobilePage.vue";
import qs from "qs";
import PcPage from "@/components/PcPage.vue";
const visible = ref(false)
const targetIndex = ref(4)
const {proxy} = getCurrentInstance()
onMounted(() => {
    let parse = qs.parse(window.location.search.substring(1));
    targetIndex.value = parse.targetImg || 4;
    document.getElementsByTagName('html')[0].className = 'MAS ' + proxy.$i18n.locale;
})

</script>

