<template>
  <div class="h-full w-full bg-cover bg-no-repeat pb-[20px]" :style="{backgroundImage:'url(' + activeData.bg + ')'}">
    <div class="flex justify-between px-[19px] pt-[20px]">
      <img src="@/assets/platform.png" class="h-[24px]"
           :style="{filter:`${index==1?'grayscale(100%) contrast(100)':''}`}">
      <div class="absolute right-[10px] top-[20px] lang-select">
        <lang-select></lang-select>
      </div>
      <!--            <img v-if="activeData.showExtra" src="@/assets/m/team_logo.png" class="h-[24px]">-->
    </div>
    <div class="text-center mt-[69px] text-white leading-[40px]" :class="text1Style">
      <div class="font-bold " :class="locale !== 'vi_VN' ? 'custom-font':''"
           :style="{color:activeData.text0Color}"
           style="text-shadow: 1px 1px 1px #FFFFFF ,1px -1px 1px #FFFFFF,-1px 1px 1px #FFFFFF,-1px -1px 1px #FFFFFF;">
        【PP88】
      </div>
      <div class="font-bold opacity-[0.9] px-[17px] leading-[30px]"
           :class="locale !== 'vi_VN' ? 'custom-font':''" :style="{color:activeData.text1Color}"
           style="text-shadow: 1px 1px 1px #FFFFFF ,1px -1px 1px #FFFFFF,-1px 1px 1px #FFFFFF,-1px -1px 1px #FFFFFF;"
           v-html="activeData.text1">
      </div>
    </div>
    <!-- 白框内容-->
    <div class="px-[17px]">
      <div class="px-[23px] py-[10px] bg-white rounded-[10px] mt-[5px]">
        <!-- 蓝框内容-->
        <div style="border: 1px solid #5780e9;" class="text-center p-[17px] rounded-[10px]">
          <div class="flex justify-center w-full">
            <img src="@/assets/m/text_logo.png" class="w-[26px] h-[36px]"/>
            <div :class="text2Style"
                 class="mx-auto" :style="{color:activeData.text2Color}"
                 v-html="activeData.text2">
            </div>
            <img src="@/assets/m/text_logo.png" class="rotate-y-[180deg] w-[26px] h-[36px]"/>
          </div>
          <div :style="{color:activeData.text3Color}" class="py-[8px] font-bold" :class="text3Style"
               style="border-bottom: 1px dashed #644f9c;" v-html="activeData.text3">
          </div>
          <div :style="{color:activeData.text4Color}" class="text-[12px] text-left pt-[14px] text-[#97A7B6]">
            {{ activeData.text4 }}
          </div>
        </div>
        <!-- 缩放按钮 -->
        <div>
          <a v-if="!isIos" class="relative block mt-[20px] text-center custom-btn" @click="doDownload" target="_parent"
             :href="getAndroidLink()" :style="{filter:isIos && iosDisable?'grayscale(100%)':''}">
            <img :src="require('@/assets/download_btn.png')" class="mb-[10px] h-[44px]"
                 style="filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.2));"/>
            <div class="absolute text-white top-50% left-50% text-center whitespace-nowrap"
                 style="transform: translate(-50%,-80%);">
              {{ $t('downloadNow') }}
            </div>
          </a>
          <a v-else class="relative block mt-[20px] text-center custom-btn" @click="doTry" target="_parent">
            <img :src="require('@/assets/download_btn.png')" class="mb-[10px] h-[44px]"
                 style="filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.2));"/>
            <div class="absolute text-white top-50% left-50% text-center whitespace-nowrap"
                 style="transform: translate(-50%,-80%);">
              {{ $t('registerNow') }}
            </div>
          </a>
<!--          <a v-if="isIos && locale ==='vi_VN'" class="relative block text-center custom-btn" @click="doDownload"-->
<!--             target="_parent"-->
<!--             href="https://www.pp88app.com/PP88_nav.mobileconfig"-->
<!--             :style="{filter:isIos && iosDisable?'grayscale(100%)':''}">-->
<!--            <img :src="require('@/assets/download_btn2.webp')" class="mb-[10px] h-[44px]"-->
<!--                 style="filter: drop-shadow(0px 5px 5px rgba(0,0,0,0.2));"/>-->
<!--            <div class="absolute text-white top-50% left-50% text-center whitespace-nowrap"-->
<!--                 style="transform: translate(-50%,-80%);">-->
<!--              {{ $t('downloadNow2') }}-->
<!--            </div>-->
<!--          </a>-->
          <div class="text-center text-[12px]">
            {{ $t('aggree') }}<span style="color: #5780E9" @click="emit('showRule')">{{
              $t('protocol')
            }}</span>
          </div>
        </div>
        <!-- introduce -->
        <div :class="activeData.wordList.length> 3? 'mt-[20px] pb-[20px]':'mt-[38px] pb-[28px]'">
          <div v-for="(item,index) in activeData.wordList" :key="index" class="flex items-start mt-[15px]">
            <img :src="item.logo" class="mr-[12px] w-[33px]"/>
            <div>
              <div class="font-bold text-[14px] ">{{ item.text1 }}</div>
              <div class="text-[12px] text-[#97A7B6] mt-[5px]">{{ item.text2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, defineProps, getCurrentInstance, onMounted, ref, watch} from "vue";
import qs from "qs";
import LangSelect from "@/components/LangSelect.vue";
import {FingerprintJSPro, fpjsPlugin, useVisitorData} from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import {useI18n} from "vue-i18n";

const {locale, t} = useI18n()
const emit = defineEmits(['showRule', 'btnClick'])
const props = defineProps({
  index: Number,
})

const linkList = [
  {name: '线路1', level: 4, timeRate: '0', link: 'https://www.pnavj.com/?jump=1'},
  {name: '线路2', level: 4, timeRate: '0', link: 'https://www.pnavj.com/?jump=1'},
  {name: '线路3', level: 4, timeRate: '0', link: 'https://www.pnavj.com/?jump=1'},
  {name: '线路4', level: 4, timeRate: '0', link: 'https://www.pnavj.com/?jump=1'},
  {name: '线路5', level: 4, timeRate: '0', link: 'https://www.pnavj.com/?jump=1'},
]
const text1Style = computed(() => {
  let res = '';
  if (activeData.value.text1.length >= 50) {
    res = 'text-[26px] leading-[25px]'
  } else {
    res = 'text-[29px]'
  }
  if (!isIos.value) {
    res += ' stoke'
  }
  return res;
})

const text2Style = computed(() => {
  let res = '';
  if (activeData.value.text2.length >= 55) {
    res = 'text-[13px] leading-[20px] scale-115';
  } else if (activeData.value.text2.length >= 50) {
    res = 'text-[15px] leading-[20px] scale-110';
  } else if (activeData.value.text2.length >= 30) {
    res = 'text-[20px] leading-[25px] scale-110'
  } else if (activeData.value.text2.length >= 20) {
    res = 'text-[26px] leading-[25px]'
  } else {
    res = 'text-[28px]'
  }
  console.log('qq-',locale)
  if (locale.value !== 'vi_VN') {
    res += ' custom-font'
  }

  return res;
})

const text3Style = computed(() => {
  if (locale.value === 'vi_VN' || locale.value === 'en_US' || locale.value === 'en_MAS') {
    return 'text-[12px]'
  } else {
    // if (activeData.value.text3?.length > 6) {
    return 'text-[15px]'
    // }
  }
})

function getEnvironment() {
  let ua = navigator.userAgent;
  let result = ''
  if (/AndroidAPP/i.test(ua)) {
    result = 'ANDROIDAPPNEW';
  } else if (/AndroidH5APP/i.test(ua)) {
    result = 'ANDROIDAPP';
  } else if (/android/i.test(ua)) {
    result = 'ANDROID';
  } else if (window.navigator.standalone) {
    result = "WEBCLIP";
  } else if (/(iPhone|iPad|iPod|IOS)/i.test(ua)) {
    result = 'IOS';
  } else {
    // /microMessenger/i.test(ua)
    result = 'ANDROID';
  }
  // Toast('sdf'+ result)
  return result;
}

const isIos = computed(() => {
  return getEnvironment() === 'IOS' || getEnvironment() === 'WEBCLIP'
})

isIos.value ? window.app.use(fpjsPlugin, {
  loadOptions: {
    apiKey: 'wBVoQlCJUBjcZ0tIX9ZA',
    // region: 'eu',
    endpoint: [
      "https://hkpp88.com/Hj05JS9WTScDmDDZ/mvea85Q5EXkg1D6v",
      FingerprintJSPro.defaultEndpoint
    ],
    scriptUrlPattern: [
      "https://hkpp88.com/Hj05JS9WTScDmDDZ/zldMPCvgGxZDbjOf?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
      FingerprintJSPro.defaultScriptUrlPattern
    ]
    // scriptUrlPattern: ['metrics.yourwebsite.com/agent-path', defaultScriptUrlPattern],
  }
}) : '';

const data = ref({})

onMounted(() => {
  if (isIos.value) {
    const {data: tData, getData} = useVisitorData(
        {extendedResult: true},
        // Set to true to fetch data on mount
        {immediate: false}
    )
    data.value = tData;
    getData().then(() => {
      let parse = qs.parse(window.location.search.substring(1));
      let uCode = parse.u_code || '';
      let sendData = {
        'uuid': tData.value.visitorId,
        'code': uCode,
      }
      var formBody = [];
      for (let property in sendData) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(sendData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      var xhr = new XMLHttpRequest();

// 配置 POST 请求，并指定 URL
      xhr.open('POST', 'https://v1.pp88app.com/set.php', true);

// 设置请求头为 'application/x-www-form-urlencoded'
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

// 发送请求
      xhr.send(formBody);

// 设置响应的加载完成后的回调函数
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          // 请求成功
        } else {
          // 请求失败
          console.error('Request failed with status:', xhr.status);
        }
      };
      return iosDisable.value = false
    })
  }
})
const iosDisable = ref(true)
watch(data, (newVal) => {
  if (newVal) {
    console.log(newVal)
  }
})


function getRandomLink() {
  // 生成一个随机索引
  const randomIndex = Math.floor(Math.random() * linkList.length);

  // 返回随机选中的URL
  return linkList[randomIndex].link ;
}

// function getRandomUrl() {
//   // 定义一个包含四个URL的数组
//   const urls = [
//     'https://www.pp88app.com/PP88_v3.mobileconfig',
//     'https://www.pp88app.com/PP88_subc.mobileconfig',
//     'https://www.pp88app.com/PP88_subv.mobileconfig',
//     'https://www.pp88app.com/PP88_suba.mobileconfig',
//   ];
//
//   // 生成一个随机索引
//   const randomIndex = Math.floor(Math.random() * urls.length);
//
//   // 返回随机选中的URL
//   return urls[randomIndex];
// }

// function getIOSLink() {
//   return getRandomUrl()
//   // return 'https://m.a51testhk.com/PP88_DEV_HK.mobileconfig'
// }

function getAndroidLink() {
  let parse = qs.parse(window.location.search.substring(1));
  let uCode = parse.u_code || '';
  return `https://pp88.onelink.me/eggO?af_xp=social&pid=app_share&deep_link_value=${uCode}&af_click_lookback=7d&af_dp=pp88game%3A%2F%2Fwelcome`
}

function doTry() {
  let parse = qs.parse(window.location.search.substring(1));
  let uCode = parse.u_code || '';
  window.open(getRandomLink() + '&u_code=' + uCode)
}

function doDownload(e) {
  if (isIos.value && iosDisable.value) {
    e.preventDefault()
    return;
  }
  if (isIos.value) {
    setTimeout(() => {
      alert(t('iosDownloadConfirm'))
      // location.href = "https://www.pp88app.com/embedded3.mobileprovision";
    }, 2000)
    // window.open(`https://www.pp88app.com/PP88_v3.mobileconfig`)
    // window.open(`https://m.a51testhk.com/PP88_DEV_HK.mobileconfig`,'_parent')

    // window.open(`https://m.a51testhk.com/embedded3.mobileprovision`)
  }
}

const activeData = computed(() => {
  return dataList[props.index]
})
const {proxy} = getCurrentInstance()
const dataList = [
  {
    bg: new URL('@/assets/m/bg1.png', import.meta.url).href,
    showExtra: true,
    text0Color: '#F9A51A',
    text1: proxy.$t('a1Text1'),
    text1Color: '#2D6CCD',
    text2: proxy.$t('a1Text2'),
    text2Color: '#F9A51A',
    text3: proxy.$t('a1Text3'),
    text3Color: '#009B4C',
    text4: proxy.$t('a1Text4'),
    wordList: [
      {
        logo: new URL('@/assets/ICON1.svg', import.meta.url).href,
        text1: proxy.$t('a1Text5'),
        text2: proxy.$t('a1Text6'),
      },
      {
        logo: new URL('@/assets/ICON2.svg', import.meta.url).href,
        text1: proxy.$t('a1Text7'),
        text2: proxy.$t('a1Text8'),
      },
      {
        logo: new URL('@/assets/ICON3.svg', import.meta.url).href,
        text1: proxy.$t('a1Text9'),
        text2: proxy.$t('a1Text10'),
      },
    ]
  },
  {
    bg: new URL('@/assets/m/bg3.png', import.meta.url).href,
    showExtra: false,
    text0Color: '#E62129',
    text1: proxy.$t('a2Text1'),
    text1Color: '#E62129',
    text2: proxy.$t('a2Text2'),
    text2Color: '#B04B87',
    text3: proxy.$t('a2Text3'),
    text3Color: '#F08C27',
    text4: proxy.$t('a2Text4'),
    wordList: [
      {
        logo: new URL('@/assets/ICON4.svg', import.meta.url).href,
        text1: proxy.$t('a2Text5'),
        text2: proxy.$t('a2Text6'),
      },
      {
        logo: new URL('@/assets/ICON5.svg', import.meta.url).href,
        text1: proxy.$t('a2Text7'),
        text2: proxy.$t('a2Text8'),
      },
      {
        logo: new URL('@/assets/ICON6.svg', import.meta.url).href,
        text1: proxy.$t('a2Text9'),
        text2: proxy.$t('a2Text10'),
      },
      {
        logo: new URL('@/assets/ICON7.svg', import.meta.url).href,
        text1: proxy.$t('a2Text11'),
        text2: proxy.$t('a2Text12'),
      },
    ]
  },
  {
    bg: new URL('@/assets/m/bg2.png', import.meta.url).href,
    showExtra: false,
    text0Color: '#F08519',
    text1: proxy.$t('a3Text1'),
    text1Color: '#F08519',
    text2: proxy.$t('a3Text2'),
    text2Color: '#0F2BFF',
    text4: proxy.$t('a3Text4'),
    wordList: [
      {
        logo: new URL('@/assets/ICON8.svg', import.meta.url).href,
        text1: proxy.$t('a3Text5'),
        text2: proxy.$t('a3Text6'),
      },
      {
        logo: new URL('@/assets/ICON9.svg', import.meta.url).href,
        text1: proxy.$t('a3Text7'),
        text2: proxy.$t('a3Text8'),
      },
      {
        logo: new URL('@/assets/ICON10.svg', import.meta.url).href,
        text1: proxy.$t('a3Text9'),
        text2: proxy.$t('a3Text10'),
      },
      {
        logo: new URL('@/assets/ICON11.svg', import.meta.url).href,
        text1: proxy.$t('a3Text11'),
        text2: proxy.$t('a3Text12'),
      },
      {
        logo: new URL('@/assets/ICON12.svg', import.meta.url).href,
        text1: proxy.$t('a3Text13'),
        text2: proxy.$t('a3Text14'),
      },
    ]
  },
  {
    bg: new URL('@/assets/m/bg5.png', import.meta.url).href,
    showExtra: false,
    text0Color: '#E40082',
    text1: proxy.$t('a4Text1'),
    text1Color: '#E40082',
    text2: proxy.$t('a4Text2'),
    text2Color: '#018FD7',
    text3: proxy.$t('a4Text3'),
    text3Color: '#018FD7',
    text4: proxy.$t('a4Text4'),
    wordList: [
      {
        logo: new URL('@/assets/ICON14.svg', import.meta.url).href,
        text1: proxy.$t('a4Text5'),
        text2: proxy.$t('a4Text6'),
      },
      {
        logo: new URL('@/assets/ICON15.svg', import.meta.url).href,
        text1: proxy.$t('a4Text7'),
        text2: proxy.$t('a4Text8'),
      },
      {
        logo: new URL('@/assets/ICON16.svg', import.meta.url).href,
        text1: proxy.$t('a4Text9'),
        text2: proxy.$t('a4Text10'),
      },
      {
        logo: new URL('@/assets/ICON17.svg', import.meta.url).href,
        text1: proxy.$t('a4Text11'),
        text2: proxy.$t('a4Text12'),
      },
      {
        logo: new URL('@/assets/ICON18.svg', import.meta.url).href,
        text1: proxy.$t('a4Text13'),
        text2: proxy.$t('a4Text14'),
      },
    ]
  },
  {
    bg: new URL('@/assets/m/bg4.png', import.meta.url).href,
    showExtra: true,
    text0Color: '#009B4C',
    text1: proxy.$t('a5Text1'),
    text1Color: '#009B4C',
    text2: proxy.$t('a5Text2'),
    text2Color: '#018FD7',
    text4: proxy.$t('a5Text4'),
    wordList: [
      {
        logo: new URL('@/assets/ICON14.svg', import.meta.url).href,
        text1: proxy.$t('a5Text5'),
        text2: proxy.$t('a5Text6'),
      },
      {
        logo: new URL('@/assets/ICON15.svg', import.meta.url).href,
        text1: proxy.$t('a5Text7'),
        text2: proxy.$t('a5Text8'),
      },
      {
        logo: new URL('@/assets/ICON19.svg', import.meta.url).href,
        text1: proxy.$t('a5Text9'),
        text2: proxy.$t('a5Text10'),
      },
      {
        logo: new URL('@/assets/ICON20.svg', import.meta.url).href,
        text1: proxy.$t('a5Text11'),
        text2: proxy.$t('a5Text12'),
      },
    ]
  }
]
</script>
<style>
:root {
  --van-dialog-background: #242A42;
  --van-text-color: #FFFFFF;
  --van-button-default-background: #617DEF;
  --van-dialog-confirm-button-text-color: #FFFFFF;
  --van-border-color: #617DEF;
}

body {
  margin: 0;
}

p {
  text-indent: 20px;
}
</style>
<style scoped>
.stoke {
  -webkit-text-stroke: 1px #FFFFFF;
}

.lang-select :deep(.lang) img {
  height: 50px;
}

.lang-select :deep(.lang) div {
  font-size: 30px;
}

.custom-font {
  font-family: 'HYYaKuHeiW';
}

.custom-btn {
  animation: myfirst 0.5s infinite alternate linear;
}

@keyframes myfirst {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
